<template>
  <van-popup
    v-model="showPicker"
    class="service-pop"
    position="right"
    closeable
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="tips" v-if="tips">
        <span><van-icon name="info-o" /></span>
        {{ tips }}
      </div>
      <div class="content">
        <div class="input-wrap">
          <van-field label="身份证" required input-align="right" />
        </div>
        <div class="input-wrap">
          <Upload
            v-model="identityCards.front"
            previewVali="mobile"
            :maxCount="1"
            :isDefaultAccept="false"
            :accept="acceptFn('PIC')"
            placeholder="请上传身份证人像面"
          />
          <Upload
            v-model="identityCards.back"
            previewVali="mobile"
            :maxCount="1"
            :isDefaultAccept="false"
            :accept="acceptFn('PIC')"
            placeholder="请上传身份证国徽面"
          />
        </div>
        <div class="input-wrap" v-for="(f, i) in fields" :key="i">
          <van-field
            :type="f.type"
            v-model.trim="form[f.key]"
            :label="f.title"
            :placeholder="`等待识别输入`"
            :disabled="f.disabled"
            :required="f.required"
            input-align="right"
          />
        </div>
      </div>
      <div v-if="ocrType == '1'" class="btns">
        <van-button size="small" @click="showPicker = false">取消</van-button>
        <van-button type="info" size="small" @click="saveFn"
          >提交认证</van-button
        >
      </div>
      <div v-else class="btns">
        <van-button size="small" @click="showPicker = false">返回</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ocrIdentify, ocrIdentifySubmit } from "@/api/apply";
export default {
  components: {
    Upload: () => import("@/components/Upload.vue")
  },
  data() {
    return {
      tips: "等待上传身份证人像面",
      identityCards: {},
      expiry: true,
      form: {}
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    prePlatOrderNo: {
      type: String,
      default: ""
    },
    ocrType: {
      // 1 申请人身份证识别，提交
      // 2 仅识别
      type: [String, Number],
      default: "1"
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    },
    fields() {
      return [
        {
          title: "姓名",
          key: "name",
          required: true,
          type: "text"
        },
        {
          title: "身份证号",
          key: "idCardNo",
          type: "text",
          // disabled: true,
          required: true,
          reg: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
        },
        {
          title: "户籍地",
          key: "address",
          required: true,
          type: "text"
        }
        // {
        //   title: "有效期",
        //   key: "date",
        //   disabled: true,
        //   required: true,
        //   type: "text"
        // }
      ];
    }
  },
  watch: {
    "identityCards.front"(v) {
      if (v && v.length) this.$set(this.form, "frontImageUrl", v[0].url);
      else this.$set(this.form, "frontImageUrl", "");
      this.ocrIdentify();
    },
    "identityCards.back"(v) {
      if (v && v.length) this.$set(this.form, "backImageUrl", v[0].url);
      else this.$set(this.form, "backImageUrl", "");

      this.ocrIdentify();
    },
    showPicker(v) {
      if (!v) {
        this.form = {};
        this.identityCards = {};
        this.tips = "等待上传身份证人像面";
      }
    }
  },
  methods: {
    async ocrIdentify() {
      try {
        const { frontImageUrl, backImageUrl } = this.form;
        const prePlatOrderNo = this.prePlatOrderNo;
        if (!frontImageUrl) {
          this.tips = "等待上传身份证人像面";
          return;
        }
        if (!backImageUrl) {
          this.tips = "等待上传身份证国徽面";
          return;
        }
        this.$toast.loading({
          message: "图片上传识别中...",
          forbidClick: true,
          loadingType: "circular",
          duration: 0
        });
        const params = {
          prePlatOrderNo,
          frontImageUrl,
          backImageUrl
        };
        const { data } = await ocrIdentify(params);
        const ed = data.expiryDate;
        const y = ed.slice(0, 4);
        const m = ed.slice(4, 6);
        const d = ed.slice(6, 8);
        this.expiry = Date.now() > Date.parse(new Date(`${y}-${m}-${d}`));
        if (this.expiry) {
          this.tips = "请提交有效期内的身份证件";
          throw this.tips;
        }

        const datum = {
          ...data,
          ...params,
          date: `${data.issueDate}-${data.expiryDate}`
        };
        this.$set(this, "form", datum);
        this.tips = "";
        this.$toast.clear();
        if (this.ocrType == "2") {
          this.$emit("success", this.form);
          this.showPicker = false;
        }
      } catch (error) {
        this.tips = error;
        this.$errMsg(error);
      } finally {
        this.$toast.clear();
      }
    },
    async saveFn() {
      try {
        await this.checkedFields();

        this.$toast.loading({
          message: "认证提交中...",
          forbidClick: true,
          loadingType: "circular",
          duration: 0
        });
        await ocrIdentifySubmit(this.form);
        this.$toast.success("提交成功");
        this.$emit("success", this.form);
        this.showPicker = false;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    checkedFields() {
      if (!this.form.frontImageUrl) {
        throw "请上传身份证人像面";
      }
      if (!this.form.backImageUrl) {
        throw "请上传身份证国徽面";
      }
      if (this.expiry) {
        this.tips = "请提交有效期内的身份证件";
        throw this.tips;
      }
      for (let i = 0; i < this.fields.length; i++) {
        const f = this.fields[i];
        const k = f.key;
        const name = f.title;
        const reg = f.reg;
        const required = f.required;
        if (required && !this.form[k]) throw `${name}不能为空`;
        if (reg && !reg.test(this.form[k])) throw `请输入正确的${name}`;
      }
      return Promise.resolve(true);
    },
    acceptFn(fileType) {
      if (fileType == "PIC") {
        return ".jpg";
      } else if (fileType == "VIDEO") {
        return "video/*";
      } else if (fileType == "DOC") {
        return ".pdf";
      } else {
        return "image/*,video/*";
      }
    }
    // uploadImg({ url }, key) {
    //   this.$set(this.form, key, url);
    // },
    // delImg(index, key) {
    //   this.$set(this.form, key, "");
    // }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    padding: 12px 24px;
    line-height: 40px;
    font-size: 24px;
    color: #f5a227;
    background: #fff1dc;
    min-height: 48px;
    box-shadow: 0 5px 5px #72634c15;
    margin-bottom: 5px;
  }
  .head {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #efefef;
  }
  .content {
    flex: 1;
    overflow-y: auto;

    .input-wrap {
      width: 100%;
      min-height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 32px;
      :deep(.van-field__label) {
        width: 7em;
      }
      :deep(.upload-ctn) {
        width: 50%;
        position: relative;
        .icon-delete {
          right: 0;
          top: 0;
          font-size: 32px;
        }
        &:first-child {
          margin-right: 16px;
        }
        &:last-child {
          margin-left: 16px;
        }
        .van-loading {
          position: absolute;
          left: 0;
          height: 100%;
          display: flex;
          top: 0;
          flex-direction: column;
          justify-content: center;
        }

        & > div {
          width: 100%;
          *:not(i) {
            display: block;
            width: calc((100vw - 96px) / 2);
          }
          .van-uploader__upload {
            display: flex;
          }
        }
      }
    }
    .color-r {
      color: red;
    }
    .color-blue {
      color: rgb(24, 144, 255);
    }
  }
  .btns {
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 45%;
      height: 75%;
    }
  }
}
</style>
